































import { Component, Prop, Emit, Vue, Watch } from 'vue-property-decorator';
import Input from '@/components/ui/input/Input.vue';
import PopupDialogDefault from '@/components/ui/popup-dialog/PopupDialogDefault.vue';
import RolesList from '@/components/user/RolesList.vue';
import { InviteData } from '@/models/invite';
import RolesModule from '@/store/modules/roles/RolesModule';
import { andCombiner, email, required } from '@/services/validators';
import Notify from '@/services/helpers/notify';
import DocumentMetaAudience from '@/modules/documents/components/document/DocumentMetaAudience.vue';
import mode from '@/store/modules/mode/mode';
import ApiClient from '@/api/api.client';
import SpecializationsModule from '@/store/modules/specializations/SpecializationsModule';
import { SubRole } from '@/typings/domain';

@Component({
  components: {
    Input,
    RolesList,
    PopupDialogDefault,
    DocumentMetaAudience,
  },
})
export default class InviteCard extends Vue {
  innerInvite: InviteData = null;
  isValid: boolean | null = null;
  @Prop({ required: true }) invite: InviteData;
  @Prop() invites: string[];
  created() {
    this.innerInvite = this.invite;
  }
  handleRedirect(event: any)
  {
    event.preventDefault();
    event.returnValue = '';
    return ''; 
  }
  mounted()
  {
    window.addEventListener('beforeunload', this.handleRedirect);
  }
  beforeDestroy()
  {
    window.removeEventListener('beforeunload', this.handleRedirect)
  }
  onEmailChange(email: string) {
    this.innerInvite.email = email;
  }
  paginateRoles(page: number)
  {
    RolesModule.fetchRoles({page: page})
  }
  async createSpecialisation(name: string)
  {
    const role_id = mode.value;
    const isNameMatched = RolesModule.roles.filter((el) => {return el.specialisations && el.id === role_id})[0].specialisations.filter((el) => el.name.toLowerCase() === name.toLowerCase())
    if(!isNameMatched.length)
    {
      ApiClient.specialisations.createSpecialisation(name, role_id).then((data: SubRole) => 
      {
        RolesModule.addSpecialization({parentId: data.role_id, specialisations: data})
        Notify.success('Специализация создана')
      })
      .finally(() => 
      {
        mode.disablePopUp();
      })
    }
    else
    {
      Notify.error('Специализация уже существует')
    }
  }
  // isDuplicate(): boolean {
  //   return this.invites.filter(el => el === this.invite.email).length > 1;
  // }

  validate() {

    this.isValid = andCombiner([required, email]).validate(this.innerInvite.email).isValid;
    if (this.isValid)
    {
      this.emitChange();
      this.validEmail(true);
    }
    else 
    {
      if(!this.innerInvite.email && this.invites[0].length === 0)
      {
        Notify.error('Заполните e-mail');
      }
      else if(this.invites[0].length < 6)
      {
        Notify.error('Некорректный e-mail');
      }

      this.validEmail(false)
      this.isValid = false;
    }
  }

  onRoleSelect(id: Array<number>) {
    this.innerInvite.specialisations = id;
    this.emitChange();
  }

  onRoleRemove(id: number) {
    const index = this.innerInvite.specialisations.indexOf(id);
    this.innerInvite.specialisations.splice(index, 1);
  }

  get popUpStatus()
  {
    return mode.popupDialogOpen
  }
  closePopup()
  {
    mode.disablePopUp();
  }

  @Emit('isValid')
  validEmail(status: boolean)
  {
    return status;
  }


  @Emit('change')
  emitChange() {
    return this.innerInvite;
  }

  get selectedSpecializations() {
    return RolesModule.specialisations.filter(specialisations =>
      this.innerInvite.specialisations.includes(specialisations.id),
    );
  }
}
